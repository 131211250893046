 
import Navbarsection from "../views/Home/Navbarsection" 
import Categoryslides from "../views/Home/Categoryslides"
 
import Footersection from "../views/Home/Footersection"
import Contactsection from "../views/Home/Contactsection"
   
export default function Contact() {
  return (
<>
 
<Navbarsection />
<Categoryslides /> 
 <Contactsection/>
 
<Footersection />
 
  
  
</>
  )
}
