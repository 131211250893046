/* eslint-disable react/no-unescaped-entities */
 
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unknown-property */
 
 
import Navbarsection from "../views/Home/Navbarsection" 
 
 import Footersection from "../views/Home/Footersection"
import Login from "../views/Account/Login"
import Signup from "../views/Account/Signup"
import { Tabs } from 'flowbite-react';
import { HiAdjustments, HiClipboardList, HiUserCircle } from 'react-icons/hi';
import { LuUserPlus2 } from "react-icons/lu"; 

export default function Account() {
  return (
<>
 
<Navbarsection />

<div class="mx-auto max-w-lg text-left">
   <h2 class="text-center text-2xl font-bold sm:text-2xl"> Our Account  </h2>
<br></br>

<div> 

<Tabs aria-label="Tabs with underline" style="underline">
 
      <Tabs.Item active title="Login" icon={HiUserCircle} className="text-white"> 
        <Login /> 
      </Tabs.Item>

      <Tabs.Item title="Signup" icon={LuUserPlus2}>  
        <Signup />
      </Tabs.Item>
      
    </Tabs>
</div>
 
</div>
 
<Footersection />
   
</>
  )
}
 