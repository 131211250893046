/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unknown-property */
 
 
import Navbarsection from "../views/Home/Navbarsection" 
import Categoryslides from "../views/Home/Categoryslides"
import Whyus from "../views/Home/Whyus"
import Footersection from "../views/Home/Footersection"
 
import Faqsection from "../views/Home/Faqsection"
 import Brandlogoslides from "../views/Home/Brandlogoslides"
import Aboutusection
 from "../views/About/Aboutusection"
export default function About() {
  return (
<>
 
<Navbarsection />
<Categoryslides /> 
<Aboutusection />
<Whyus /> 
 <Faqsection />
 <Brandlogoslides />
<Footersection />
 
  
  
</>
  )
}
