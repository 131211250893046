 
 
import Loginform from "./Loginform"
 
export default function login() {
  return (
    <> 
    
    <Loginform />
   
    </>
  )
}
