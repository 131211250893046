/* eslint-disable react/no-unknown-property */
 
import Catlogsection from "../views/Home/Catlogsection"
import Navbarsection from "../views/Home/Navbarsection"
import Bannerslides from "../views/Home/Bannerslides"
import Categoryslides from "../views/Home/Categoryslides"
import Whyus from "../views/Home/Whyus"
import Footersection from "../views/Home/Footersection"
import Blogsection from "../views/Home/Blogsection"
import Faqsection from "../views/Home/Faqsection"
 import Brandlogoslides from "../views/Home/Brandlogoslides"
 
export default function Home() {
  return (
<>
 
<Navbarsection />
<Categoryslides />
<Bannerslides />
<Whyus />
 <Catlogsection />
 <Blogsection />
 <Faqsection />
 <Brandlogoslides />
<Footersection />
  
</>
  )
}
 