/* eslint-disable react/jsx-no-undef */
/* eslint-disable no-unused-vars */
import React from "react"
import ReactDom from "react-dom/client" 
 import About from "./Pages/About.jsx";
 import Account from "./Pages/Account.jsx"; 
 import Blog from "./Pages/Blog.jsx";
 import Cart from "./Pages/Cart.jsx";
 import Category from "./Pages/Category.jsx";
 import Checkout from "./Pages/Checkout.jsx";
 import Location from "./Pages/Location.jsx";
 import Offer from "./Pages/Offer.jsx";
 import Products from "./Pages/Products.jsx";
 import Search from "./Pages/Search.jsx";
 

 import Contact from "./Pages/Contact.jsx";

  
 import Home from "./Pages/Home.jsx";
 import Aboutusection from "./views/About/Aboutusection.jsx";
 import CartSection from "./views/Cart/CartSection.jsx";
 
import { createBrowserRouter, RouterProvider } from "react-router-dom";
  
const router = createBrowserRouter([ 
  { 
    path: '/',
    element: <Home /> 
  }, 
  { 
    path: '/About',
    element: <About /> 
  }, 
  { 
    path: '/Home',
    element: <Home /> 
  }, 

  { 
    path: '/Account',
    element: <Account /> 
  },

  { 
    path: '/Blog',
    element: <Blog /> 
  },

  { 
    path: '/Cart',
    element: <Cart /> 
  },

  { 
    path: '/Category',
    element: <Category /> 
  },

  { 
    path: '/Checkout',
    element: <Checkout /> 
  },

  { 
    path: '/Location',
    element: <Location /> 
  },

  { 
    path: '/Offer',
    element: <Offer /> 
  },

  { 
    path: '/Products',
    element: <Products /> 
  },

  { 
    path: '/Search',
    element: <Search /> 
  },

  { 
    path: '/CartSection',
    element: <CartSection /> 
  }, 
  { 
    path: '/Aboutusection',
    element: <Aboutusection /> 
  }, 
  { 
    path: '/Contact',
    element: <Contact /> 
  } 

   
]);
 

ReactDom.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
  <RouterProvider router={router} />
  </React.StrictMode>
)

export default router;