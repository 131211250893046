 

/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unknown-property */
 
 
import Navbarsection from "../views/Home/Navbarsection" 
import Categoryslides from "../views/Home/Categoryslides"
 import Footersection from "../views/Home/Footersection"
import Offersection from "../views/Home/Offersection"
import Blogsection from "../views/Home/Blogsection"

 
export default function blog() {
  return (
<>
 
<Navbarsection />
<Categoryslides />  
<Blogsection />
<Footersection />
  
  
</>
  )
}
 