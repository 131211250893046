/* eslint-disable no-unused-vars */
import React from 'react'
 

export default function Checkout() {
  return (
    <div>Checkout</div>
    
  )
}
