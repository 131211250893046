
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unknown-property */
 
 
import Navbarsection from "../views/Home/Navbarsection" 
import Categoryslides from "../views/Home/Categoryslides"
 import Footersection from "../views/Home/Footersection"
import Categorysection from "../views/Category/Categorysection"
 
export default function Category() {
  return (
<>
 
<Navbarsection />
<Categoryslides />  
<Categorysection />
<Footersection />
 
  
  
</>
  )
}
