 
import Navbarsection from "../views/Home/Navbarsection" 
import Categoryslides from "../views/Home/Categoryslides"
 import Footersection from "../views/Home/Footersection" 
import CartSection from "../views/Cart/CartSection"
 
export default function Cart() {
  return (
<>
 
<Navbarsection />
<Categoryslides />  
<CartSection/>
<Footersection />
   
</>
  )
}
 