/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unknown-property */
 import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import React, { useEffect } from "react"
import Glide from "@glidejs/glide"
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 2000, min: 1000 },
    items: 5
  },
  desktop: {
    breakpoint: { max: 2000, min: 900 },
    items: 4
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 3
  }
};
export default function Logoslide() {
  return (
   <>
   
   <h2 class="w-1/2 p-4 mx-auto text-center	 text-2xl font-bold sm:text-4xl"> Our Trusted Partner </h2>
<br></br>
 
<Carousel responsive={responsive}  className="z-10 overscroll-x-none P-3" >
  <div className="p-8"> <figure className=" position:absolute   inline-block align-middle">
  <img src="https://cdn2.tendercuts.in/news_article/c71133d6-b26b-4d66-bd87-49ff4b7e81a4.png" className="h-18 w-20 
    rounded --300" alt="" />
  <figcaption className="place-content-center" > Brand 1 </figcaption>
</figure>
 </div>


 <div className="p-8"> <figure className=" position:absolute   inline-block align-middle">
  <img src="https://cdn2.tendercuts.in/news_article/588c1812-43f2-476f-8809-bb4f952a1799.png" className="h-18 w-20 
    rounded --300" alt="" />
  <figcaption className="place-content-center" > Brand 2 </figcaption>
</figure>
 </div>


 <div className="p-8"> <figure className=" position:absolute   inline-block align-middle">
  <img src="https://cdn2.tendercuts.in/news_article/8a9bba70-12b3-4784-b8a9-4f6d92a861e4.png" className="h-18 w-20 
    rounded --300" alt="" />
  <figcaption className="place-content-center" > Brand 3</figcaption>
</figure>
 </div>

 <div className="p-8"> <figure className=" position:absolute   inline-block align-middle">
  <img src="https://imgs.search.brave.com/nKjWi661Ks-T9JURpSLZWKTfwzsO5IX4rLPv0xCfeHc/rs:fit:860:0:0/g:ce/aHR0cHM6Ly9zcC1h/by5zaG9ydHBpeGVs/LmFpL2NsaWVudC90/b193ZWJwLHFfZ2xv/c3N5LHJldF9pbWcs/d183NTAsaF80MDAv/aHR0cDovL2Fzc2V0/cy5kZXNpZ25oaWxs/LmNvbS9kZXNpZ24t/YmxvZy93cC1jb250/ZW50L3VwbG9hZHMv/MjAxOS8wNC8zLnBu/Zw" className="h-18 w-20 
    rounded --300" alt="" />
  <figcaption className="place-content-center" > Brand 4</figcaption>
</figure>
 </div>

 <div className="p-8"> <figure className=" position:absolute   inline-block align-middle">
  <img src="https://imgs.search.brave.com/uXs6TPdtmwDpJrAeSP3uXZ5hI19QfFC4siddk-CkkVc/rs:fit:860:0:0/g:ce/aHR0cHM6Ly9pbWFn/ZXMtcGxhdGZvcm0u/OTlzdGF0aWMuY29t/Ly9BU2ViTnFlN0do/enlmSkp2MXExRXBP/aHE0ZFU9LzB4MDox/ODc1eDE4NzUvZml0/LWluLzUwMHg1MDAv/cHJvamVjdHMtZmls/ZXMvMzIvMzI5OC8z/Mjk4NjYvNzMyMGFh/MjUtYTJjOS00Nzc1/LTkwYjUtZjQ4OTM3/ZTc2NGMwLmpwZw" className="h-18 w-20 
    rounded --300" alt="" />
  <figcaption className="place-content-center" > Brand 5</figcaption>
</figure>
 </div>

</Carousel>

  
   
   </>
  )
}
