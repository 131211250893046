  
import Signupform from "./Signupform"
export default function Signup() {
  return (
    <> 
 
    <Signupform />
  
    </>
  )
}
