/* eslint-disable react/no-unknown-property */
import { Button, Checkbox, Label, TextInput } from 'flowbite-react';
import { MdOutlinePassword } from "react-icons/md";

import { HiMail } from 'react-icons/hi';

export default function Loginform() {
  return (
  <>  
   <form className="p-8 flex max-w-md flex-col gap-4">
      <div>
        <div className="mb-2 block">
          <Label htmlFor="email1" value="Your email" />
        </div>
        <TextInput id="email4" type="email" addon="@"  rightIcon={HiMail} placeholder="name@flowbite.com" required />
      </div>
      <div>
        <div className="mb-2 block">
          <Label htmlFor="password1" value="Your password"  />
        </div>
        <TextInput id="password1" type="password" rightIcon={MdOutlinePassword}required />
      </div>
      <div className="flex items-center gap-2">
        <Checkbox id="remember" />
        <Label htmlFor="remember">Remember me</Label>
      </div>
      <Button className="bg-indigo-700 inline-flex items-center" type="submit">Login 
      
      <svg  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-4">
  <path strokeLinecap="round" strokeLinejoin="round" d="m5.25 4.5 7.5 7.5-7.5 7.5m6-15 7.5 7.5-7.5 7.5" />
</svg>
 </Button>
  
    </form>
    
  </>
  )
}
