/* eslint-disable react/no-unknown-property */
/* eslint-disable react/jsx-no-undef */
import { Card } from 'flowbite-react';

export default function Whyus() {
  return (
   <> 
   <section className="items-center flex flex-col" >
  
  <a href="#" className="items-center flex flex-col bg-white border border-gray-200 rounded-lg shadow md:flex-row md:max-w-xl hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700">
  <img src="https://www.tendercuts.in/assets/elite/tc-elite-logo-red.png" alt="" className="w-19 h-12" />
      <div className="flex flex-col justify-between p-4 leading-normal">
          <h5 className="mb-2 text-1xl font-bold tracking-tight text-gray-900 dark:text-white">Special Offer From BhaaratFresh | New Updates </h5>
          <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">Get Best Discount From BhaaratFresh </p> <button type="button" className="text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-5 mb-7"> Checkout Our Offer </button>
  
      </div>
  </a>
  
  </section>
  <br></br>

  <section>
<h2 class="w-1/2 p-4 mx-auto text-center	 text-2xl font-bold sm:text-4xl"> Why Choose Bhaarat Fresh  </h2>
<br></br>
  <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
      <div className="grid gap-5 row-gap-5 sm:grid-cols-2 lg:grid-cols-4">


        <div className="px-12 text-center sm:px-0">
        <Card className=" " imgSrc="/images/blog/image-4.jpg" >
          <div className="flex items-center justify-center w-10 h-10 mx-auto mb-4 rounded-full bg-indigo-50 sm:w-12 sm:h-12">
            <svg
              className="w-8 h-8 text-deep-purple-accent-400 sm:w-10 sm:h-10"
              stroke="currentColor"
              viewBox="0 0 52 52"
            >
              <polygon
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
                fill="none"
                points="29 13 14 29 25 29 23 39 38 23 27 23"
              />
            </svg>
          </div>
          <h6 className="mb-2 text-sm font-bold leading-5 tracking-wider uppercase">
            Fearless
          </h6>
          <div className="mb-2 text-gray-700">
            I will remember and recover, not forgive and forget.
          </div>
          </Card>
        </div>

        <div className="px-12 text-center sm:px-0">
        <Card className=" " imgSrc="/images/blog/image-4.jpg" >
          <div className="flex items-center justify-center w-10 h-10 mx-auto mb-4 rounded-full bg-indigo-50 sm:w-12 sm:h-12">
            <svg
              className="w-8 h-8 text-deep-purple-accent-400 sm:w-10 sm:h-10"
              stroke="currentColor"
              viewBox="0 0 52 52"
            >
              <polygon
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
                fill="none"
                points="29 13 14 29 25 29 23 39 38 23 27 23"
              />
            </svg>
          </div>
          <h6 className="mb-2 text-sm font-bold leading-5 tracking-wider uppercase">
            Honest
          </h6>
          <div className="mb-2 text-gray-700">
            I have nothing to lose but something to gain.
          </div>
          </Card>
        </div>


        <div className="px-12 text-center sm:px-0">
        <Card className=" " imgSrc="/images/blog/image-4.jpg" >
          <div className="flex items-center justify-center w-10 h-10 mx-auto mb-4 rounded-full bg-indigo-50 sm:w-12 sm:h-12">
            <svg
              className="w-8 h-8 text-deep-purple-accent-400 sm:w-10 sm:h-10"
              stroke="currentColor"
              viewBox="0 0 52 52"
            >
              <polygon
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
                fill="none"
                points="29 13 14 29 25 29 23 39 38 23 27 23"
              />
            </svg>
          </div>
          <h6 className="mb-2 text-sm font-bold leading-5 tracking-wider uppercase">
            Beautiful
          </h6>
          <div className="mb-2 text-gray-700">
            White is not always light and black is not always dark.
          </div>
          </Card>
        </div>



        <div className="px-12 text-center sm:px-0">
        <Card className=" " imgSrc="/images/blog/image-4.jpg" >
          <div className="flex items-center justify-center w-10 h-10 mx-auto mb-4 rounded-full bg-indigo-50 sm:w-12 sm:h-12">
            <svg
              className="w-8 h-8 text-deep-purple-accent-400 sm:w-10 sm:h-10"
              stroke="currentColor"
              viewBox="0 0 52 52"
            >
              <polygon
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
                fill="none"
                points="29 13 14 29 25 29 23 39 38 23 27 23"
              />
            </svg>
          </div>
          <h6 className="mb-2 text-sm font-bold leading-5 tracking-wider uppercase">
            Fantastic
          </h6>
          <div className="mb-2 text-gray-700">
            The meaning of life is to give life meaning.
          </div>
          </Card>
        </div>


      </div>
    </div>
</section>


  </>
  )
}
 